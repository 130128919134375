import styled from 'styled-components'
import { Warpper } from '@raylink-overseas/common/components'
import { Media } from '@raylink-overseas/common/theme'

export const ExperienceWrap = styled(Warpper)`
  ${Media.laptop`
    width: 1136px;
  `}
  ${Media.ipad`
    width: 944px;
  `}
  ${Media.phone`
    width: 6.88rem;
  `}
`

export const ExperienceWeb = styled.div`
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 508px;
  }
  .right {
    width: 636px;
  }
  ${Media.homeLaptop`
    .right {
      width: 605px;
    }
  `}
  ${Media.laptop`
    .right {
      width: 571px;
    }
  `}
  ${Media.ipad`
    display: none;
  `}
`

export const ExperienceItem = styled.div<{ isActive: boolean }>`
  padding: 24px 32px;
  line-height: 1.25;
  .e-title {
    font-size: 24px;
    font-weight: 700;
    color: #1f2329;
    cursor: pointer;
  }
  .desc {
    font-size: 16px;
    color: #595959;
  }

  ${({ isActive }) =>
    isActive
      ? `
        padding: 32px 32px 34px;
        border-radius: 16px;
        border: 2px solid #e4f4fe;
        background: linear-gradient(
            135deg,
            rgba(228, 244, 254, 0) 52.59%,
            rgba(218, 229, 255, 0.76) 79.09%,
            #d0d7ff 103.79%,
            #d0d7ff 143.03%
          ),
          #fff;
        box-shadow: 0px 16px 40px 2px rgba(240, 245, 255, 0.7);
      `
      : ''}
`

export const ExperienceMobile = styled.div`
  display: none;
  ${Media.ipad`
    width: 752px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: auto;
  `}
  ${Media.phone`
    width: 6.88rem;
    gap: 0.32rem;
  `}
`

export const ExperienceMobileItem = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 16px 16px 16px 32px;
  border-radius: ${({ isActive }) => (isActive ? '24px' : '8px')};
  border: 2px solid #e4f4fe;
  box-shadow: 0px 16px 40px 2px rgba(240, 245, 255, 0.7);
  background: linear-gradient(
      135.01deg,
      rgba(228, 244, 254, 0) 62.74%,
      rgba(218, 229, 255, 0.76) 93.48%,
      #d0d7ff 143.03%
    ),
    linear-gradient(0deg, #ffffff, #ffffff);
  .l-title {
    font-size: 20px;
    font-weight: 700;
    color: #1f2329;
    cursor: pointer;
  }
  .l-desc {
    margin-top: 8px;
    font-size: 16px;
    color: #595959;
  }
  .right-img {
    width: 330px;
  }
  ${Media.phone<{ isActive: boolean }>`
    flex-direction: column;
    gap: 0.48rem;
    padding: ${({ isActive }) => (isActive ? '0.48rem' : '0.32rem 0')};
    border-radius: ${({ isActive }) => (isActive ? '0.48rem' : '0.16rem')};
    border: 0.04rem solid #e4f4fe;
    .l-title {
      font-size: 0.36rem;
    }
    .l-desc {
      margin-top: 0.16rem;
      font-size: 0.28rem;
    }
    .right-img {
      width: 5.92rem;
    }
  `}
`
