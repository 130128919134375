import React from 'react'
import { BusinessBannerWrap, BusinessBannerContent, Left } from './atom'
import ButtonList from '@raylink-overseas/common/components/buttonList'
import Path from '@raylink-overseas/common/constants/path'

export default function BusinessBanner({ ...rest }) {
  return (
    <BusinessBannerWrap {...rest}>
      <BusinessBannerContent>
        <Left>
          <div className="tag">Avica Business</div>
          <h1 className="title">
            Best <span>All-In-One</span> Remote Solution for Business
          </h1>
          <div className="desc">
            Avica Business offers a robust enterprise-grade remote desktop
            solution that combines exceptional performance with secure remote
            access, enabling to optimize of productivity.
          </div>
          <ButtonList
            className="btn-list-div"
            btnInfo={[
              { text: 'Start Business Trial', href: Path.businessLogin },
              {
                text: 'Contact Sales',
                href: `${Path.contact}#customize-your-plan`,
              },
            ]}
          />
        </Left>
      </BusinessBannerContent>
    </BusinessBannerWrap>
  )
}
