import styled from 'styled-components'
import BestBgSrc from '../../../../../images/home/start-now-bg.png'
import { Media, color } from '@raylink-overseas/common/theme'

export const BestWrap = styled.div`
  width: 100%;
  height: 444px;
  min-width: 1400px;
  background: url(${BestBgSrc}) no-repeat center top;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  ${Media.homeLaptop`
    min-width: 1184px;
    height: 362px;
    svg {
      width: 176px;
    }
  `}
  ${Media.laptop`
    min-width: 1088px;
    height: 286px;
    svg {
      width: 168px;
    }
  `}
  ${Media.ipad`
    min-width: 896px;
  `}
  ${Media.phone`
    height: 5.5rem;
    min-width: auto;
    padding: 0.6rem 0 1.34rem;
    display: block;
  `}
`

export const BestContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .start {
    font-size: 40px;
    font-weight: 800;
    line-height: 1.3;
    color: #1f2329;
    margin: 32px 0;
    span {
      background: linear-gradient(
        279deg,
        #56bcee 0.5%,
        #445fff 37.33%,
        #3764f6 69.92%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .btns {
    display: flex;
    gap: 24px;
    .start-download {
      width: 159px;
      height: 48px;
      font-size: 16px;
    }
    .pricing {
      width: 159px;
      padding: 14px 20px;
      background: transparent;
      border: 2px solid ${color.primary};
      color: ${color.primary};
      &:hover {
        background: rgba(68, 95, 255, 0.06);
      }
      :active {
        background: rgba(68, 95, 255, 0.12);
      }
    }
  }
  ${Media.homeLaptop`
    .start {
      font-size: 34px;
      margin: 24px 0;
    }
  `}
  ${Media.laptop`
    .start {
      font-size: 32px;
      margin: 20px 0;
    }
  `}
  ${Media.ipad`
    .start {
      font-size: 30px;
    }
  `}
  ${Media.phone`
    svg {
      width: 2.72rem;
      height: 0.72rem;
    }
    .start {
      font-size: 0.56rem;
      margin: 0.4rem 0;
      padding: 0;
      text-align: center;
    }
    .btns {
      gap: 0.32rem;
      .start-download {
        width: 2.88rem;
        height: 0.86rem;
        font-size: 0.3rem;
      }
      .pricing {
        width: 2.88rem;
        height: 0.86rem;
        padding: 0.24rem 0.32rem;
        margin-left: 0.32rem;
      }
    }
  `}
`
