import styled from 'styled-components'
import { Warpper } from '@raylink-overseas/common/components'
import { Media } from '@raylink-overseas/common/theme'

export const MeetWrap = styled(Warpper)`
  ${Media.laptop`
    width: 1136px;
  `}
  ${Media.ipad`
    width: 944px;
  `}
  ${Media.phone`
    width: 6.88rem;
  `}
`

export const MeetContentWeb = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ul {
    display: flex;
    gap: 16px;
  }
  ${Media.phone`
    display: none;
  `}
`

export const FeatItem = styled.li<{ bg: string; isActive: boolean }>`
  width: ${({ isActive }) => (isActive ? '534px' : '389px')};
  height: 296px;
  overflow: hidden;
  border-radius: 16px;
  background: linear-gradient(
      180deg,
      rgba(35, 35, 35, 0) 57.21%,
      rgba(35, 35, 35, 0.4) 70.05%,
      rgba(35, 35, 35, 0.6) 82.88%,
      rgba(35, 35, 35, 0.9) 100%
    ),
    url(${({ bg }) => bg || ''}) 50% / cover no-repeat;
  transition: all 0.6s;
  .content-div {
    margin-top: ${({ isActive }) => (isActive ? '176px' : '244px')};
    padding: 0 32px;
    color: #fff;
    transition: all 0.6s;
    .f-title {
      font-size: 24px;
      font-weight: 700;
    }
    .desc {
      margin-top: 8px;
      opacity: ${({ isActive }) => (isActive ? '1' : '0')};
      font-size: 16px;
    }
  }
  .six {
    margin-top: ${({ isActive }) => (isActive ? '196px' : '244px')};
  }
  ${Media.homeLaptop` 
    .content-div {
      .f-title {
        font-size: 22px;
      }
    }
  `}
  ${Media.laptop<{ bg: string; isActive: boolean }>`
    .six {
      margin-top: ${({ isActive }) => (isActive ? '176px' : '244px')};
    }
  `}
  ${Media.ipad<{ bg: string; isActive: boolean }>`
    background: linear-gradient(
      180deg,
      rgba(35, 35, 35, 0) 41.39%,
      rgba(35, 35, 35, 0.4) 58.97%,
      rgba(35, 35, 35, 0.6) 76.55%,
      rgba(35, 35, 35, 0.9) 100%
    ),
    url(${({ bg }) => bg || ''}) 50% / cover no-repeat;
    .content-div {
      margin-top: ${({ isActive }) => (isActive ? '158px' : '247px')};
      padding: ${({ isActive }) => (isActive ? '0 32px' : '0 0 0 22px')};
      .f-title {
        font-size: 20px;
      }
    }
    .six {
      margin-top: ${({ isActive }) => (isActive ? '178px' : '247px')};
    }
  `}
`

export const MeetContentMobile = styled.div`
  display: none;
  ${Media.phone`
    display: flex;
    flex-wrap: wrap;
    gap: 0.24rem;
  `}
`

export const MeetItemMobile = styled.div<{ bg: string }>`
  ${Media.phone<{ bg: string }>`
    position: relative;
    width: 3.31rem;
    height: 2.72rem;
    border-radius: 0.24rem;
    background: linear-gradient(
        180deg,
        rgba(35, 35, 35, 0) 57.21%,
        rgba(35, 35, 35, 0.4) 70.05%,
        rgba(35, 35, 35, 0.6) 82.88%,
        rgba(35, 35, 35, 0.9) 100%
      ),
      url(${({ bg }) => bg || ''}) 50% / cover no-repeat;
    .content {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0.24rem;
      font-size: 0.36rem;
      color: #fff;
      font-weight: 700;
      text-align: center;
    }
  `}
`
