import React from 'react'
import { Title } from '../../atoms'
import { NextLevelWrap, NextLevelList, NextLevelItem } from './atom'
import { nextLevelList } from '../../config'

export default function NextLevel({ ...rest }) {
  return (
    <NextLevelWrap {...rest}>
      <Title>Take Enterprise Remote Desktop to the Next Level</Title>
      <NextLevelList>
        {nextLevelList.map(item => (
          <NextLevelItem key={item.title}>
            <img className="n-icon" src={item.imgSrc} loading="lazy" />
            <div className="n-title">{item.title}</div>
            <div className="n-desc">{item.desc}</div>
          </NextLevelItem>
        ))}
      </NextLevelList>
    </NextLevelWrap>
  )
}
