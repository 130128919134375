import styled from 'styled-components'
import { Media } from '@raylink-overseas/common/theme'

export const BusinessWrap = styled.div`
  width: 100%;
  line-height: 1.26;
  .btn-list-div {
    .first,
    .second {
      width: unset;
      padding: 20px;
    }
  }
  ${Media.laptop`
    .btn-list-div {
      .first,
      .second {
        height: 51px;
      }
    }
  `}
  ${Media.ipad`
    .btn-list-div {
      .first,
      .second {
        height: 48px;
        font-size: 16px;
      }
    }
  `}
  ${Media.phone`
    line-height: 1.25;
    .btn-list-div {
      gap: 0.32rem;
      .first,
      .second {
        height: 0.88rem;
        padding: 0.3rem;
        font-size: 0.32rem;
      }
    }
  `}
`

export const Title = styled.h2`
  margin: 112px 0 56px;
  font-size: 40px;
  font-weight: 700;
  color: #1f2329;
  text-align: center;
  .desc {
    margin-top: 8px;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #595959;
  }
  ${Media.homeLaptop`
    margin: 88px 0 48px;
    font-size: 34px;
  `}
  ${Media.laptop`
    margin: 64px 0 40px;
    font-size: 32px;
  `}
  ${Media.ipad`
    margin: 56px auto 32px;
    font-size: 30px;
    .desc {
      margin-top: 6px;
      font-size: 16px;
    }
  `}
  ${Media.phone`
    margin: 0.8rem 0 0.48rem;
    font-size: 0.48rem;
    .desc {
      margin-top: 0.12rem;
      font-size: 0.32rem;
    }
  `}
`
