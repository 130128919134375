import React, { useState } from 'react'
import { Title } from '../../atoms'
import {
  MeetWrap,
  MeetContentWeb,
  FeatItem,
  MeetContentMobile,
  MeetItemMobile,
} from './atom'
import { meetList } from '../../config'

export default function Meet({ ...rest }) {
  const [activeId, setActiveId] = useState('one')
  const meetMobileList = meetList[0].featList.concat(meetList[1].featList)

  return (
    <MeetWrap {...rest}>
      <Title>Meet Remote Work Needs Across Various Industries</Title>
      <MeetContentWeb>
        {meetList.map(item => (
          <ul key={item.name}>
            {item.featList.map(feat => (
              <FeatItem
                key={feat.title}
                bg={feat.bg}
                isActive={activeId === feat.id}
                onMouseEnter={() => {
                  setActiveId(feat.id)
                }}
              >
                <div className={`content-div ${feat.id}`}>
                  <h3 className="f-title">{feat.title}</h3>
                  <div className="desc">{feat.desc}</div>
                </div>
              </FeatItem>
            ))}
          </ul>
        ))}
      </MeetContentWeb>
      <MeetContentMobile>
        {meetMobileList.map(item => (
          <MeetItemMobile key={item.title} bg={item.bg}>
            <div className="content">{item.title}</div>
          </MeetItemMobile>
        ))}
      </MeetContentMobile>
    </MeetWrap>
  )
}
