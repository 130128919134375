import styled from 'styled-components'
import { Warpper } from '@raylink-overseas/common/components'
import Bg from '../../../../../images/products/business/bannerBg.png'
import BgMobile from '../../../../../images/products/business/bannerBgMobile.png'
import { Media } from '@raylink-overseas/common/theme'

export const BusinessBannerWrap = styled.div`
  position: relative;
  width: 100%;
  height: 640px;
  min-width: 1200px;
  background: url(${Bg}) center center / auto 100% no-repeat;
  ${Media.laptop`
    height: 576px;
    min-width: 1136px;
  `}
  ${Media.ipad`
    height: 480px;
    min-width: 944px;
  `}
  ${Media.phone`
    background: url(${BgMobile}) center center / auto 100% no-repeat;
    height: 11.2rem;
    min-width: 100%;
  `}
`
export const BusinessBannerContent = styled(Warpper)`
  height: 100%;
  ${Media.laptop`
    width: 1136px;
  `}
  ${Media.ipad`
    width: 944px;
  `}
  ${Media.phone`
    width: 100%;
  `}
`

export const Left = styled.div`
  width: 468px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .tag {
    width: fit-content;
    padding: 6px 16px;
    font-size: 16px;
    color: #1f2329;
    border-radius: 42px;
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.6);
  }
  .title {
    margin: 16px 0;
    font-size: 40px;
    color: #1f2329;
    font-weight: 800;
    span {
      background: linear-gradient(
        97.7deg,
        #56bcee 19.9%,
        #445fff 34.39%,
        #3764f6 55.25%
      );
      background-clip: text;
      -moz-background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .desc {
    margin-bottom: 40px;
    font-size: 18px;
    color: #595959;
  }
  ${Media.ipad`
    width: 394px;
    .title {
      margin: 8px 0;
      font-size: 34px;
    }
    .desc {
      margin-bottom: 32px;
      font-size: 16px;
    }
  `}
  ${Media.phone`
    width: 6.88rem;
    height: auto;
    justify-content: flex-start;
    margin: auto;
    padding: 1.54rem 0 0;
    .tag {
      padding: 0.12rem 0.32rem;
      font-size: 0.28rem;
      border-radius: 0.84rem;
    }
    .title {
      margin: 0.16rem 0;
      font-size: 0.52rem;
    }
    .desc {
      margin-bottom: 0.48rem;
      font-size: 0.28rem;
    }
  `}
`
