import styled from 'styled-components'
import { Warpper } from '@raylink-overseas/common/components'
import { Media } from '@raylink-overseas/common/theme'

export const NextLevelWrap = styled(Warpper)`
  ${Media.laptop`
    width: 1136px;
  `}
  ${Media.ipad`
    width: 944px;
  `}
  ${Media.phone`
    width: 6.88rem;
  `}
`

export const NextLevelList = styled.div`
  display: flex;
  gap: 32px;
  ${Media.homeLaptop`
    gap: 24px;
  `}
  ${Media.phone`
    flex-direction: column;
    gap: 0.32rem;
  `}
`

export const NextLevelItem = styled.div`
  padding: 40px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 1px solid #e6edff;
  background: radial-gradient(
      112.37% 137.26% at 55.55% -18.06%,
      rgba(171, 214, 250, 0) 72.62%,
      rgba(153, 168, 255, 0.2) 100%
    ),
    linear-gradient(0deg, #f9fbff, #f9fbff);
  .n-icon {
    width: 88px;
  }
  .n-title {
    margin: 8px 0;
    font-size: 24px;
    font-weight: 700;
    color: #1f2329;
  }
  .n-desc {
    font-size: 16px;
    line-height: 1.5;
    color: #595959;
    text-align: center;
  }
  ${Media.homeLaptop`
    padding: 32px 32px 40px;
    .n-title {
      font-size: 22px;
    }
  `}
  ${Media.laptop`
    .n-title {
      font-size: 20px;
    }
  `}
  ${Media.ipad`
    padding: 24px 24px 32px;
  `}
  ${Media.phone`
    padding: 0.32rem 0.32rem 0.64rem 0.48rem;
    border-radius: 0.32rem;
    .n-icon {
      width: 1.76rem;
    }
    .n-title {
      margin: 0.16rem 0;
      font-size: 0.36rem;
    }
    .n-desc {
      font-size: 0.32rem;
    }
  `}
`
