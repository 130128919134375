import React, { useEffect, useState } from 'react'
import { Title } from '../../atoms'
import {
  ExperienceWrap,
  ExperienceWeb,
  ExperienceItem,
  ExperienceMobile,
  ExperienceMobileItem,
} from './atom'
import { experienceList } from '../../config'

export default function Experience({
  playStatus,
  ...rest
}: {
  playStatus: boolean
}) {
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    if (!playStatus) return undefined
    const interval = setTimeout(() => {
      if (activeIndex === experienceList.length - 1) {
        setActiveIndex(0)
      } else {
        setActiveIndex(pre => pre + 1)
      }
    }, 3000)
    return () => {
      clearInterval(interval)
    }
  }, [playStatus, activeIndex])

  return (
    <ExperienceWrap {...rest}>
      <Title>
        Experience the Best Performance
        <p className="desc">
          Avica Business stands out for its exceptional capabilities and
          embedded tools that enhance remote productivity.
        </p>
      </Title>

      <div id="experience-id">
        {/* pc端 */}
        <ExperienceWeb>
          <div className="left">
            {experienceList.map((item, index) => (
              <ExperienceItem
                key={item.title}
                isActive={activeIndex === index}
                onClick={() => setActiveIndex(index)}
              >
                <h3 className="e-title">{item.title}</h3>
                {activeIndex === index && (
                  <div className="e-desc">{item.desc}</div>
                )}
              </ExperienceItem>
            ))}
          </div>
          <img
            src={experienceList[activeIndex]?.imgSrc}
            alt=""
            className="right"
            loading="lazy"
          />
        </ExperienceWeb>

        {/* ipad及手机端 */}
        <ExperienceMobile>
          {experienceList.map((item, index) => (
            <ExperienceMobileItem
              key={item.title}
              isActive={activeIndex === index}
              onClick={() => setActiveIndex(index)}
            >
              {activeIndex === index ? (
                <>
                  <div className="left">
                    <div className="l-title">{item.title}</div>
                    <div className="l-desc">{item.desc}</div>
                  </div>
                  <img
                    src={item.imgSrc}
                    alt=""
                    className="right-img"
                    loading="lazy"
                  />
                </>
              ) : (
                <div className="l-title">{item.title}</div>
              )}
            </ExperienceMobileItem>
          ))}
        </ExperienceMobile>
      </div>
    </ExperienceWrap>
  )
}
