import React from 'react'
import Arrow from '../../../../../images/products/business/arrowIcon.svg'
import Check from '../../../../../images/products/business/check-icon.svg'
import { Title } from '../../atoms'
import NotFoldTable from '../../../components/noFoldTable'
import { tableList, KeyEnum } from '../../config'
import { LearnMore } from '../explore/atom'
import { ComparetableWrap, TableTitle } from './atom'
import Path from '@raylink-overseas/common/constants/path'

export default function CompareTable() {
  const priceData = {
    standard: '79.00',
    performance: '129.00',
  }

  const officeTitle = ({
    title,
    desc,
    num,
    href,
  }: {
    title: string
    desc: string
    num: string
    href: string
  }) => (
    <TableTitle>
      <div className="t-title">{title}</div>
      <div className="t-desc">
        <span>{num}</span>
        {desc}
      </div>
      <a href={href} className="btn" target="_blank">
        Buy Now
      </a>
    </TableTitle>
  )

  const titleList = [
    {
      label: officeTitle({
        title: 'Standard',
        desc: ' / User / Year',
        num: priceData.standard,
        href: Path.buyStandard,
      }),
      key: KeyEnum.standard,
    },
    {
      label: officeTitle({
        title: 'Performance',
        desc: ' / User / Year',
        num: priceData.performance,
        href: Path.buyPerformance,
      }),
      key: KeyEnum.performance,
    },
  ]

  return (
    <ComparetableWrap>
      <Title className="c-title">Ideal Plan for Your Business</Title>
      <NotFoldTable
        compareList={tableList}
        titleList={titleList}
        checkImg={Check}
        headerText=""
      />
      <LearnMore className="learn-more">
        <a href={Path.businessPrice} target="_blank">
          Learn More
        </a>
        <img className="arrow-icon" alt="" src={Arrow} loading="lazy" />
      </LearnMore>
    </ComparetableWrap>
  )
}
