import { Warpper } from '@raylink-overseas/common/components'
import { Media } from '@raylink-overseas/common/theme'
import styled from 'styled-components'

export const AcceptWrap = styled(Warpper)`
  width: 1015px;
  padding: 0 0 112px;
  ${Media.homeLaptop`
    padding: 0 0 88px;
  `}
  ${Media.laptop`
    padding: 0 0 64px;
  `}
  ${Media.ipad`
    width: 944px;
    padding: 0 0 56px;
  `}
  ${Media.phone`
    width: 100%;
    padding: 0 0.48rem 0.8rem;
  `}
`

export const AcceptListWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 48px 56px;
  ${Media.ipad`
    gap: 32px 40px;
  `}
  ${Media.phone`
    display: none;
  `}
`

export const AcceptListPhoneWrap = styled.div`
  display: none;
  ${Media.phone`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.4rem;
    img {
      height: 0.7rem;
    }
    img:nth-child(3), img:nth-child(8) {
      height: 0.64rem;
    }
    img:nth-child(4) {
      height: 0.58rem;
    }
    img:nth-child(5) {
      height: 0.6rem;
    }
    img:nth-child(6) {
      height: 0.46rem;
    }
    img:nth-child(7) {
      height: 0.62rem;
    }
    img:nth-child(9), img:nth-child(10) {
      height: 0.66rem;
    }
  `}
`

export const AcceptItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #595959;
  img {
    width: 140px;
    margin-bottom: 10px;
  }
`
