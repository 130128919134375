import styled from 'styled-components'
import { Warpper } from '@raylink-overseas/common/components'
import { Media } from '@raylink-overseas/common/theme'

export const ExploreWrap = styled(Warpper)`
  ${Media.laptop`
    width: 1136px;
  `}
  ${Media.ipad`
    width: 944px;
  `}
  ${Media.phone`
    width: 6.88rem;
  `}
`

export const ExploreContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 80px 78px;
  border-radius: 24px;
  background: linear-gradient(
    180deg,
    #d0d7ff 46.4%,
    rgba(222, 226, 255, 0) 100%
  );
  .e-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px 0;
  }
  ${Media.homeLaptop`
    padding: 72px 64px;
  `}
  ${Media.laptop`
    padding: 64px 56px;
  `}
  ${Media.ipad`
    padding: 40px 32px;
  `}
  ${Media.phone`
    padding: 0.36rem;
    border-radius: 0.32rem;
    text-align: center;
    .e-list {
      gap: 0.28rem;
    }
  `}
`

export const ExploreItem = styled.div`
  width: 332px;
  padding: 32px 0 40px;
  border-radius: 12px;
  border: 1px solid #fff;
  background: #fff;
  .title-div {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
    padding-left: 32px;
    font-size: 20px;
    font-weight: 700;
    font-size: #1f2329;
    .e-icon {
      width: 40px;
    }
  }
  .desc {
    padding: 0 32px;
    font-size: 16px;
    color: #595959;
  }
  ${Media.homeLaptop`
    width: 340px;
  `}
  ${Media.laptop`
    width: 325px;
  `}
  ${Media.ipad`
    width: 277px;
  `}
  ${Media.phone`
    width: 2.94rem;
    padding: 0.24rem;
    border-radius: 0.24rem;
    .title-div {
      flex-direction: column;
      gap: 0.04rem;
      margin-bottom: 0.12rem;
      padding-left: 0;
      font-size: 0.28rem;
      .e-icon {
        width: 0.8rem;
      }
    }
    .desc {
      padding: 0;
      font-size: 0.24rem;
    }
  `}
`

export const LearnMore = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  margin: auto;
  a {
    font-size: 16px;
    color: #445fff;
    cursor: pointer;
  }
  .arrow-icon {
    width: 23px;
    transition: all 0.4s ease-in-out;
  }
  @media (any-hover: hover) {
    &:hover {
      .arrow-icon {
        transform: translateX(8px);
      }
    }
  }
  ${Media.phone`
    a {
      font-size: 0.32rem;
    }
    .arrow-icon {
      width: 0.46rem;
    }
  `}
`
