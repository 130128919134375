import React from 'react'
import { Title } from '../../atoms'
import { acceptList } from '../../config'
import {
  AcceptWrap,
  AcceptListWrap,
  AcceptListPhoneWrap,
  AcceptItem,
} from './atom'

export default function Accept() {
  return (
    <AcceptWrap>
      <Title>Highly Recognized by</Title>
      <AcceptListWrap>
        {acceptList.map(item => (
          <AcceptItem key={item.name}>
            <img src={item.imgSrc} alt="" loading="lazy" />
            {item.name}
          </AcceptItem>
        ))}
      </AcceptListWrap>
      <AcceptListPhoneWrap>
        {acceptList.map(item => (
          <img src={item.imgMobileSrc} alt="" loading="lazy" key={item.name} />
        ))}
      </AcceptListPhoneWrap>
    </AcceptWrap>
  )
}
