import React from 'react'
import Path from '@raylink-overseas/common/constants/path'
import ArrowIcon from '../../../../../images/products/business/arrow.png'
import { securityList } from '../../config'
import { Title } from '../../atoms'
import {
  SecurityWrap,
  SecurityContent,
  SecurityList,
  SecurityItemWrap,
  SecurityItem,
  FeatWrap,
  FeatItem,
} from './atom'
import ButtonList from '@raylink-overseas/common/components/buttonList'

export default function Security({ ...rest }) {
  return (
    <SecurityWrap {...rest}>
      <div className="security-bg" />
      <SecurityContent>
        <Title>
          Comprehensive Enterprise-Level Security Assurance
          <p className="desc">
            Enforce various security measures throughout the entire remote
            access process to ensure the safety of data for businesses or teams.
          </p>
        </Title>
        <SecurityList>
          {securityList.map((item, index) => (
            <SecurityItemWrap key={item.name} className={item.class}>
              <SecurityItem>
                <div className="name">{item.name}</div>
                <FeatWrap>
                  <h3 className="s-title">{item.title}</h3>
                  <div className="feat-list">
                    {item.featList.map(f => (
                      <FeatItem key={f.title}>
                        <img className="f-icon" src={f.imgSrc} loading="lazy" />
                        <div className="f-right">
                          <div className="f-title">{f.title}</div>
                          <div className="f-desc">{f.desc}</div>
                        </div>
                      </FeatItem>
                    ))}
                  </div>
                </FeatWrap>
              </SecurityItem>
              {index < securityList.length - 1 && (
                <img
                  alt=""
                  src={ArrowIcon}
                  className="arrow-img"
                  loading="lazy"
                />
              )}
            </SecurityItemWrap>
          ))}
        </SecurityList>
        <ButtonList
          className="btn-list-div"
          btnInfo={[
            { text: 'Start Business Trial', href: Path.businessLogin },
            {
              text: 'Contact Sales',
              href: `${Path.contact}#customize-your-plan`,
            },
          ]}
        />
      </SecurityContent>
    </SecurityWrap>
  )
}
