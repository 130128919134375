import React from 'react'
import { BtnWrap, LineBtn, PrimaryBtn } from './atoms'
import Path from '../../constants/path'

type ButtonListProps = {
  className?: string
  btnInfo: {
    text: string
    href: string
  }[]
}
const ButtonList = ({
  btnInfo = [{ text: 'Free Download', href: Path.downloads }],
  ...rest
}: ButtonListProps) => {
  return (
    <BtnWrap {...rest}>
      <PrimaryBtn href={btnInfo[0]?.href} target="_blank" className="first">
        {btnInfo[0]?.text}
      </PrimaryBtn>
      {btnInfo[1] && (
        <LineBtn href={btnInfo[1]?.href} target="_blank" className="second">
          {btnInfo[1]?.text}
        </LineBtn>
      )}
    </BtnWrap>
  )
}

export default ButtonList
