import styled from 'styled-components'
import { Warpper } from '@raylink-overseas/common/components'
import Bg from '../../../../../images/products/business/securityBg.png'
import TitleBg1 from '../../../../../images/products/business/titleBg1.svg'
import TitleBg2 from '../../../../../images/products/business/titleBg2.svg'
import TitleBg3 from '../../../../../images/products/business/titleBg3.svg'
import { Media } from '@raylink-overseas/common/theme'

export const SecurityWrap = styled.div`
  position: relative;
  padding-bottom: 112px;
  .security-bg {
    position: absolute;
    top: 71px;
    left: 0;
    width: 100%;
    height: 932px;
    background: url(${Bg}) no-repeat top center / auto 100%;
  }
  .btn-list-div {
    margin-top: 56px;
    justify-content: center;
  }
  ${Media.homeLaptop`
    padding-bottom: 88px;
    .security-bg {
      top: 38px;
    }
  `}
  ${Media.laptop`
    padding-bottom: 64px;
    .security-bg {
      top: 10px;
    }
    .btn-list-div {
      margin-top: 40px;
    }
  `}
  ${Media.ipad`
    padding-bottom: 56px;
    .security-bg {
      top: 200px;
      height: 736px;
    }
    .btn-list-div {
      margin-top: 32px;
    }
  `}
  ${Media.phone`
    padding-bottom: 0.8rem;
    background: linear-gradient(180deg, #FFFFFF 0%, #EEF8FF 100%);
    .security-bg {
      display: none;
    }
    .btn-list-div {
      margin-top: 0.4rem;
    }
  `}
`

export const SecurityContent = styled(Warpper)`
  ${Media.laptop`
    width: 1136px;
  `}
  ${Media.ipad`
    width: 944px;
  `}
  ${Media.phone`
    width: 6.88rem;
  `}
`

export const SecurityList = styled.div`
  display: flex;
  justify-content: space-between;
  .one {
    .name {
      width: 122px;
      background: url(${TitleBg1}) no-repeat top left / 100% auto;
    }
  }
  .two {
    .name {
      width: 197px;
      background: url(${TitleBg2}) no-repeat top left / 100% auto;
    }
  }
  .three {
    .name {
      width: 151px;
      background: url(${TitleBg3}) no-repeat top left / 100% auto;
    }
  }
  ${Media.phone`
    flex-direction: column;
    gap: 0.32rem;
    .one {
      .name {
        width: 2.66rem;
      }
    }
    .two {
      .name {
        width: 4rem;
      }
    }
    .three {
      .name {
        width: 3.18rem;
      }
    }
    >div:nth-child(2) {
      .arrow-img {
        top: 8.16rem;
      }
    }
  `}
`

export const SecurityItemWrap = styled.div`
  position: relative;
  width: 372px;
  .arrow-img {
    position: absolute;
    top: 265px;
    left: 356px;
    z-index: 1;
  }
  ${Media.laptop`
    width: 353px;
    .arrow-img {
      top: 265px;
      left: 348px;
    }
  `}
  ${Media.ipad`
    width: 298px;
    .arrow-img {
      top: 276px;
      left: 280px;
    }
  `}
  ${Media.phone`
    width: 100%;
    .arrow-img {
      top: 7.74rem;
      left: 5.36rem;
      width: 1.16rem;
      transform: rotate(90deg);
    }
  `}
`

export const SecurityItem = styled.div`
  position: relative;
  .name {
    height: 72px; // 背景色高度统一，宽度不统一
    margin-bottom: -32px;
    padding: 10px 0 0 24px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
  }
  ${Media.ipad`
    .name {
      padding: 9px 0 0 24px;
    }
  `}
  ${Media.phone`
    .name {
      height: 1.44rem;
      margin-bottom: -0.64rem;
      padding: 0.18rem 0 0 0.48rem;
      font-size: 0.32rem;
    }
  `}
`

export const FeatWrap = styled.div`
  height: 448px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0 0;
  border-radius: 24px;
  background: linear-gradient(180deg, rgba(232, 246, 255, 0.8) 0%, #fff 11.3%);
  backdrop-filter: blur(16px);
  .s-title {
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 700;
    color: #1f2329;
  }
  .feat-list {
    width: 308px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  ${Media.laptop`
    .s-title {
      margin-bottom: 27px;
      font-size: 20px;
    }
    .feat-list {
      width: 297px;
      gap: 20px;
    }
  `}
  ${Media.ipad`
    height: 512px;
    padding: 24px 24px 0;
    .s-title {
      margin-bottom: 16px;
    }
    .feat-list {
      width: 250px;
      gap: 12px;
    }
  `}
  ${Media.phone`
    height: auto;
    display: flex;
    padding: 0.48rem 0 0.64rem;
    border-radius: 0.48rem;
    .s-title {
      margin-bottom: 0.48rem;
      font-size: 0.4rem;
    }
    .feat-list {
      width: 5.92rem;
      gap: 0.32rem;
    }
  `}
`

export const FeatItem = styled.div`
  display: flex;
  gap: 12px;
  line-height: 1.28;
  .f-icon {
    width: 36px;
    height: fit-content;
  }
  .f-right {
    .f-title {
      margin-bottom: 4px;
      font-size: 18px;
      font-weight: 700;
      color: #1f2329;
    }
    .f-desc {
      font-size: 16px;
      color: #595959;
    }
  }
  ${Media.ipad`
    flex-direction: column;
    gap: 8px;
    .f-right {
      .f-title {
        font-size: 16px;
      }
      .f-desc {
        font-size: 14px;
      }
    }
  `}
  ${Media.phone`
    flex-direction: row;
    gap: 0.24rem;
    .f-icon {
      width: 0.72rem;
    }
    .f-right {
      .f-title {
        margin-bottom: 0.08rem;
        font-size: 0.32rem;
      }
      .f-desc {
        font-size: 0.28rem;
      }
    }
  `}
`
