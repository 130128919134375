import React from 'react'
import { BestWrap, BestContent } from './atoms'
import Logo from '@svg/logo.svg'
import ButtonList from '@raylink-overseas/common/components/buttonList'
import Path from '@raylink-overseas/common/constants/path'

export default function Best() {
  return (
    <BestWrap>
      <BestContent>
        <Logo />
        <div className="start">
          Best <span>All-In-One</span> Remote Solution for Business
        </div>
        <div className="btns">
          <ButtonList
            className="btn-list-div"
            btnInfo={[
              { text: 'Start Business Trial', href: Path.businessLogin },
              {
                text: 'Contact Sales',
                href: `${Path.contact}#customize-your-plan`,
              },
            ]}
          />
        </div>
      </BestContent>
    </BestWrap>
  )
}
