import Next1 from '../../../images/products/business/next1.png'
import Next2 from '../../../images/products/business/next2.png'
import Next3 from '../../../images/products/business/next3.png'
import Security1 from '../../../images/products/business/security1.png'
import Security2 from '../../../images/products/business/security2.png'
import Security3 from '../../../images/products/business/security3.png'
import Security4 from '../../../images/products/business/security4.png'
import Security5 from '../../../images/products/business/security5.png'
import Security6 from '../../../images/products/business/security6.png'
import Security7 from '../../../images/products/business/security7.png'
import Security8 from '../../../images/products/business/security8.png'
import Security9 from '../../../images/products/business/security9.png'
import Experien1 from '../../../images/products/business/experien1.png'
import Experien2 from '../../../images/products/business/experien2.png'
import Experien3 from '../../../images/products/business/experien3.png'
import Explore1 from '../../../images/products/business/explore1.png'
import Explore2 from '../../../images/products/business/explore2.png'
import Explore3 from '../../../images/products/business/explore3.png'
import Explore4 from '../../../images/products/business/explore4.png'
import Explore5 from '../../../images/products/business/explore5.png'
import Explore6 from '../../../images/products/business/explore6.png'
import Meet1 from '../../../images/products/business/meet1.png'
import Meet2 from '../../../images/products/business/meet2.png'
import Meet3 from '../../../images/products/business/meet3.png'
import Meet4 from '../../../images/products/business/meet4.png'
import Meet5 from '../../../images/products/business/meet5.png'
import Meet6 from '../../../images/products/business/meet6.png'
import Accept2 from '../../../images/products/business/accept/2.png'
import Accept3 from '../../../images/products/business/accept/3.png'
import Accept4 from '../../../images/products/business/accept/4.png'
import Accept5 from '../../../images/products/business/accept/5.png'
import Accept6 from '../../../images/products/business/accept/6.png'
import Accept7 from '../../../images/products/business/accept/7.png'
import Accept8 from '../../../images/products/business/accept/8.png'
import Accept9 from '../../../images/products/business/accept/9.png'
import Accept10 from '../../../images/products/business/accept/10.png'
import AcceptMobile2 from '../../../images/products/business/accept/mobile2.png'
import AcceptMobile3 from '../../../images/products/business/accept/mobile3.png'
import AcceptMobile4 from '../../../images/products/business/accept/mobile4.png'
import AcceptMobile5 from '../../../images/products/business/accept/mobile5.png'
import AcceptMobile6 from '../../../images/products/business/accept/mobile6.png'
import AcceptMobile7 from '../../../images/products/business/accept/mobile7.png'
import AcceptMobile8 from '../../../images/products/business/accept/mobile8.png'
import AcceptMobile9 from '../../../images/products/business/accept/mobile9.png'
import AcceptMobile10 from '../../../images/products/business/accept/mobile10.png'

export const nextLevelList = [
  {
    title: 'Quick Deployment',
    desc: 'No complex configuration is required and support AD Integration and cross-system batch deployment to reduce communication costs.',
    imgSrc: Next1,
  },
  {
    title: 'One-Stop Management',
    desc: 'Use the all-in-one platform for efficient management of groups, users, roles, devices, access permissions, and all related data.',
    imgSrc: Next2,
  },
  {
    title: 'Easy to Use',
    desc: `The interface is intuitive, requiring no enterprise's IT framework changes. It does not raise the learning cost for the staff.`,
    imgSrc: Next3,
  },
]

export const securityList = [
  {
    name: 'Preset',
    title: 'User & Device Management',
    class: 'one',
    featList: [
      {
        title: 'Role & Access Management',
        desc: 'Configure user roles, devices, and functional permissions for precise control.',
        imgSrc: Security1,
      },
      {
        title: 'Two-factor Authentication',
        desc: 'Prevent unauthorized account misuse through multi-identity verification.',
        imgSrc: Security2,
      },
      {
        title: 'Device-Account Paired Binding',
        desc: 'One-to-one account-device binding ensures zero risk of account misuse.',
        imgSrc: Security3,
      },
    ],
  },
  {
    name: 'During Session',
    title: 'Guaranteed Security Process',
    class: 'two',
    featList: [
      {
        title: 'End-to-End Encryption',
        desc: 'Ensure secure data transmission by adopting the AES-256 advanced encryption.',
        imgSrc: Security4,
      },
      {
        title: 'Secure File Transfer',
        desc: 'Manage the sending and receiving permissions to enhance the security of file transfers.',
        imgSrc: Security5,
      },
      {
        title: 'Privacy Protection',
        desc: 'Employ various security settings to protect confidentiality, such as privacy mode, watermark.',
        imgSrc: Security6,
      },
    ],
  },
  {
    name: 'Log Audit',
    title: 'Operational Log Traceability',
    class: 'three',
    featList: [
      {
        title: 'Session History',
        desc: 'Detailed records of login, device, remote connection events, etc. can all be traceable.',
        imgSrc: Security7,
      },
      {
        title: 'File Transfer History',
        desc: 'File transfer details are recorded for convenient auditing, including user, device, file path, etc.',
        imgSrc: Security8,
      },
      {
        title: 'Console Operation History',
        desc: 'Admin console actions are logged for quick review and accountability.',
        imgSrc: Security9,
      },
    ],
  },
]

export const experienceList = [
  {
    title: 'Global Stable Connections',
    desc: 'Deploy the global dedicated access network for enhanced stability in remote connections for businesses.',
    imgSrc: Experien1,
  },
  {
    title: 'Ultra-Low Latency',
    desc: 'Ensure precise and rapid responses with ultra-low latency as low as 10ms, rivaling local performance.',
    imgSrc: Experien2,
  },
  {
    title: 'High Image Quality',
    desc: 'Experience ultra-HD image for seamless handling of tasks like text, code, and design work.',
    imgSrc: Experien3,
  },
]

export const exploreList = [
  {
    title: '4:4:4 Color Mode',
    desc: 'With 4:4:4 color mode, colors are transmitted without any loss.',
    imgSrc: Explore1,
  },
  {
    title: 'Multi-Monitor Support',
    desc: 'Access and interact with multiple remote monitors on your local device.',
    imgSrc: Explore2,
  },
  {
    title: 'Mobile Device Access',
    desc: 'Access your desktop devices from your mobiles anytime, anywhere.',
    imgSrc: Explore3,
  },
  {
    title: 'Digitizer',
    desc: 'Enhance the efficiency of remote design by connecting the graphics tablet.',
    imgSrc: Explore4,
  },
  {
    title: 'Screen Recording',
    desc: 'Record and save the remote access and support activities in specified locations.',
    imgSrc: Explore5,
  },
  {
    title: 'Unattended Access',
    desc: 'With the right permission, access the remote device even when no user is present.',
    imgSrc: Explore6,
  },
]

export const meetList = [
  {
    name: 'first-row',
    featList: [
      {
        id: 'one',
        title: 'Software Development',
        desc: 'Secure access to complex development tools and databases. Low latency improves remote development efficiency.',
        bg: Meet1,
      },
      {
        id: 'two',
        title: 'Film Production',
        desc: 'Fast response, high-definition image quality, and smooth remote editing of high-resolution video, all accelerate post-production.',
        bg: Meet2,
      },
      {
        id: 'three',
        title: 'Graphic Design',
        desc: 'Empower your creativity with high-fidelity color transmission and a responsive digital tablet, wherever you are.',
        bg: Meet3,
      },
    ],
  },
  {
    name: 'second-row',
    featList: [
      {
        id: 'four',
        title: '3D Modeling',
        desc: 'Achieve seamless results in modeling, sculpting, rigging, and texture rendering with high frame rates and ultra-clear image quality.',
        bg: Meet4,
      },
      {
        id: 'five',
        title: 'Interior Design',
        desc: 'Enhance efficiency and customer satisfaction with remote access to design software, real-time adjustment, and presentation of design plans.',
        bg: Meet5,
      },
      {
        id: 'six',
        title: 'IT Support',
        desc: 'Provide remote support to employees for operational guidance, problem diagnosis, and troubleshooting.',
        bg: Meet6,
      },
    ],
  },
]

export const KeyEnum = {
  standard: 'standard',
  performance: 'performance',
}

export const tableList = [
  {
    name: 'Licensed User',
    rows: [
      { key: KeyEnum.standard, value: '1' },
      { key: KeyEnum.performance, value: '1' },
    ],
  },
  {
    name: 'Concurrent Connection',
    rows: [
      { key: KeyEnum.standard, value: '1' },
      { key: KeyEnum.performance, value: '1' },
    ],
  },
  {
    name: 'Concurrent Sessions',
    rows: [
      { key: KeyEnum.standard, value: 'Unlimited' },
      { key: KeyEnum.performance, value: 'Unlimited' },
    ],
  },
  {
    name: 'Managed Devices per License',
    rows: [
      { key: KeyEnum.standard, value: '10' },
      { key: KeyEnum.performance, value: '10' },
    ],
  },
  {
    name: 'Best Quality',
    rows: [
      { key: KeyEnum.standard, value: '2K 30FPS' },
      { key: KeyEnum.performance, value: '4K 60FPS / 2K 144FPS' },
    ],
  },
  {
    name: 'Color Mode',
    rows: [
      { key: KeyEnum.standard, value: '4 : 2 : 0' },
      { key: KeyEnum.performance, value: '4 : 4 : 4' },
    ],
  },
  {
    name: 'Role & Access Management',
    rows: [
      { key: KeyEnum.standard, value: true },
      { key: KeyEnum.performance, value: true },
    ],
  },
  {
    name: 'AD Integration',
    rows: [
      { key: KeyEnum.standard, value: true },
      { key: KeyEnum.performance, value: true },
    ],
  },
  {
    name: 'Session History',
    rows: [
      { key: KeyEnum.standard, value: true },
      { key: KeyEnum.performance, value: true },
    ],
  },
  {
    name: 'File Transfer',
    rows: [
      { key: KeyEnum.standard, value: true },
      { key: KeyEnum.performance, value: true },
    ],
  },
  {
    name: 'Privacy Mode',
    rows: [
      { key: KeyEnum.standard, value: true },
      { key: KeyEnum.performance, value: true },
    ],
  },
  {
    name: 'Digitizer',
    rows: [
      { key: KeyEnum.standard, value: false },
      { key: KeyEnum.performance, value: true },
    ],
  },
  {
    name: 'Gamepad',
    rows: [
      { key: KeyEnum.standard, value: false },
      { key: KeyEnum.performance, value: true },
    ],
  },
]

export const acceptList = [
  {
    name: 'ISO27001 Certification',
    imgSrc: Accept2,
    imgMobileSrc: AcceptMobile2,
  },
  {
    name: 'HIPAA Verified',
    imgSrc: Accept5,
    imgMobileSrc: AcceptMobile5,
  },
  {
    name: 'TPN Verified',
    imgSrc: Accept3,
    imgMobileSrc: AcceptMobile3,
  },
  {
    name: 'Member of iabm',
    imgSrc: Accept7,
    imgMobileSrc: AcceptMobile7,
  },
  {
    name: 'WHQL Certified',
    imgSrc: Accept4,
    imgMobileSrc: AcceptMobile4,
  },
  {
    name: 'Member of DPP',
    imgSrc: Accept8,
    imgMobileSrc: AcceptMobile8,
  },
  {
    name: 'DPP Production Certified',
    imgSrc: Accept9,
    imgMobileSrc: AcceptMobile9,
  },
  {
    name: 'DPP Broadcast Certified',
    imgSrc: Accept10,
    imgMobileSrc: AcceptMobile10,
  },
  {
    name: 'Red Hat Certified',
    imgSrc: Accept6,
    imgMobileSrc: AcceptMobile6,
  },
]
