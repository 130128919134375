import styled, { css } from 'styled-components'
import { Media, color } from '@raylink-overseas/common/theme'

export const BtnWrap = styled.div`
  display: flex;
  gap: 16px;
  ${Media.phone`
    display: flex;
    gap: 0.2rem;
  `}
`

const BtnCommon = css`
  width: 182px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 6px;
  background: linear-gradient(290deg, #445fff 53.77%, #55bcee 97.08%);
  box-shadow: 2px 4px 10px 0px rgba(255, 255, 255, 0.2) inset;
  :hover {
    color: #fff;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.05) 0%,
        rgba(255, 255, 255, 0.05) 100%
      ),
      linear-gradient(290deg, #445fff 53.77%, #55bcee 97.08%);
  }
  ${Media.homeLaptop`
    width: 167px;
    height: 48px;
  `}

  ${Media.phone`
    width: 2.88rem;
    height: 0.86rem;
    font-size: 0.3rem;
    border-radius: 0.12rem;
  `}
`

export const PrimaryBtn = styled.a`
  ${BtnCommon}
`

export const LineBtn = styled.a`
  ${BtnCommon}
  color: ${color.primary};
  border: 2px solid ${color.primary};
  background: transparent;
  :hover {
    color: ${color.primary};
    background: rgba(68, 95, 255, 0.06);
  }
  :active {
    background: rgba(68, 95, 255, 0.12);
  }
`
