import React, { useEffect, useState } from 'react'
import {
  TableWrap,
  TableHeader,
  HeaderContent,
  TableContent,
  TableRow,
} from './atom'
import CheckIcon from '../../../../images/products/business/check-icon.svg'
import CloseIcon from '../../../../images/close-icon.svg'
import TipImg from '../../../../images/tip-icon.svg'
import { useMedia } from '@raylink-overseas/common/hooks'

interface RowItem {
  key: string
  value: string | boolean
  tip?: string
  mobileValue?: string
  mobileFontSmall?: string
  href?: string
  hrefText?: string
}
interface TableItem {
  name: string
  rows: RowItem[]
  tip?: string
}
interface Props {
  className?: string
  style?: React.CSSProperties
  compareList: TableItem[]
  titleList: { label: React.JSX.Element; key: string }[]
  checkImg?: string
  headerText?: string
}

export default function NotFoldTable({
  className,
  style,
  compareList,
  titleList,
  checkImg,
  headerText,
}: Props) {
  const [isSticky, setIsSticky] = useState(false) // 是否吸顶
  const [stickyTop, setStickyTop] = useState(72) // 表格header相对屏幕高度=导航栏高度，可能包含通知公告、顶部横幅
  const isMedia = useMedia()

  const handleHeaderChange = () => {
    const headerEle = document.getElementById('header-box')
    setStickyTop(headerEle?.clientHeight || 0)
  }

  useEffect(() => {
    if (typeof window === 'undefined') return undefined
    handleHeaderChange()

    // header有变更（关闭通知公告或顶部横幅），重新计算header的高度
    const callback = (mutationList: MutationRecord[]) => {
      mutationList.forEach((mutation: MutationRecord) => {
        switch (mutation.type) {
          // 从树上添加或移除一个或更多的子节点时触发
          case 'childList':
            handleHeaderChange()
            break
          default:
            break
        }
      })
    }
    const headerEle = document.getElementById('header-box')
    if (!headerEle) return
    const observerOptions = {
      childList: true, // 观察目标子节点的变化，是否有添加或者删除
      attributes: false, // 观察属性变动
    }
    const observer = new MutationObserver(callback) // 监听某个DOM元素变化
    observer.observe(headerEle, observerOptions)

    function scrollFunc() {
      const tableHeader = document.getElementById('tableHeader')
      if (!tableHeader) return
      // 吸顶时(header相对table的高度 > 0)
      // 1. 展示底部边线  2. 移动端增加空白伪元素解决rem计算误差
      setIsSticky(tableHeader.offsetTop > 0)
    }
    window.addEventListener('scroll', scrollFunc)
    return () => window.removeEventListener('scroll', scrollFunc)
  }, [])

  const renderRow = (row: RowItem, key: string) => {
    const { value, mobileValue, mobileFontSmall, tip, href, hrefText } = row
    const rowValue = isMedia && mobileValue ? mobileValue : value

    let element: any =
      typeof rowValue === 'boolean' ? (
        <img
          src={rowValue ? checkImg || CheckIcon : CloseIcon}
          alt=""
          loading="lazy"
        />
      ) : (
        { rowValue, tip, href, hrefText }
      )
    element = Array.isArray(element) ? element : [element]

    return (
      <div
        className="col"
        key={key}
        data-font-small={mobileFontSmall && mobileFontSmall.toString()}
      >
        {element.map((e: any, i: number) => (
          <div className="col-item" key={i}>
            {e.rowValue ? e.rowValue : e}
            {e.tip && (
              <div className="tip">
                <img src={TipImg} alt="" loading="lazy" />
                <div className="float">
                  <div>{row.tip}</div>
                  <a href={e.href}>{e.hrefText}</a>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    )
  }

  return (
    <TableWrap className={className} style={style}>
      <TableHeader id="tableHeader" isSticky={isSticky} stickyTop={stickyTop}>
        <HeaderContent
          isSticky={isSticky}
          length={titleList.length}
          headerText={headerText}
        >
          {titleList.map(t => (
            <div key={t.key}>{t.label}</div>
          ))}
        </HeaderContent>
      </TableHeader>
      <TableContent>
        {compareList.map((row: TableItem) => (
          <TableRow
            key={row.name}
            length={row.rows.length}
            className="table-row"
          >
            {/* 功能名 */}
            <div className="name">
              <span>{row.name}</span>
              {row?.tip && (
                <div className="tip">
                  <img src={TipImg} alt="" loading="lazy" />
                  <div className="float">{row?.tip}</div>
                </div>
              )}
            </div>
            {row.rows.map((r: RowItem, i: number) =>
              renderRow(r, `${row.name}-${i}`)
            )}
          </TableRow>
        ))}
      </TableContent>
    </TableWrap>
  )
}
