import React, { useEffect, useState } from 'react'
import { Layout } from '@raylink-overseas/common/components'
import {
  BusinessBanner,
  NextLevel,
  Security,
  Experience,
  Explore,
  Meet,
  CompareTable,
  Best,
  Accept,
} from './component'
import { BusinessWrap } from './atoms'
import { isElementInViewport } from '@raylink-overseas/common/utils'

export default function Business() {
  const [playStatus, setPlayStatus] = useState(false)

  useEffect(() => {
    // 有播放效果的组件，当组件出现在浏览器可视化区域大于自身1/2时开始播放效果
    const experienceEl = document.getElementById('experience-id')
    const handle = () => {
      setPlayStatus(!!(experienceEl && isElementInViewport(experienceEl)))
    }
    window.addEventListener('scroll', handle)

    return () => {
      window.removeEventListener('scroll', handle)
    }
  }, [])

  return (
    <Layout pageType="productBusiness">
      <BusinessWrap>
        <BusinessBanner />
        <NextLevel />
        <Security />
        <Experience playStatus={playStatus} />
        <Explore />
        <Meet />
        <CompareTable />
        <Accept />
        <Best />
      </BusinessWrap>
    </Layout>
  )
}
